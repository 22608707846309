
function generateEvent(s, args) {
	var evt = document.createEvent("CustomEvent");
	evt.initCustomEvent(s, false, false, args);
	return evt;
};

function createEvent(target) {
	var eventTarget = document.createElement('div');
	target.addEventListener = eventTarget.addEventListener.bind(eventTarget);
	target.removeEventListener = eventTarget.removeEventListener.bind(eventTarget);
	target.dispatchEvent = eventTarget.dispatchEvent.bind(eventTarget);
	return eventTarget;
}

export { createEvent, generateEvent }