<template>
    <div class="iq-top-navbar">
        <nav class="navbar navbar-expand-lg">
            <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
                <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" >
                    {{ item.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
            <head-center></head-center>
        </nav>
    </div>
</template>
<script>
export default {
    data() {
        return {
            breadList: [] // 路由集合
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb();
        }
    },
    methods: {
        isHome(route) {
            return route.name === "首页";
        },
        getBreadcrumb() {
            let matched = this.$route.matched;
            if (!this.isHome(matched[0])) {
                matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
            }
            this.breadList = matched;
        }
    },
    created() {
        this.getBreadcrumb();
    }
}
</script>
<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}
</style>

