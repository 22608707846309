import { createEvent, generateEvent } from './CreateEvent.js';
import { ChatHubSocket } from './ChatHubSocket.js'

let ChatHubExchange = function (addr, timeout) {
    let _this = this;
    var eventTarget = createEvent(_this);

    _this.Addr = addr;
    if (timeout) {
        _this.Timeout = timeout;
    } else {
        _this.Timeout = 10000;
    }

    _this.Socket = new ChatHubSocket(addr);
    _this.Socket.addEventListener('message', function (event) {
        OnMessage.call(_this, event.data, eventTarget);
    });
    _this.Socket.addEventListener('close', function (evt) {
        // console.log(evt);
        var event = generateEvent('Close');
        event.Key = 'Close';
        event.Timestamp = Date.now();
        event.Data = evt.reason;
        eventTarget.dispatchEvent(event);
    });
    _this.Socket.addEventListener('error', function (evt) {
        // console.log(evt);
        var event = generateEvent('Error');
        event.Key = 'Error';
        event.Timestamp = Date.now();
        event.Data = evt.message;
        eventTarget.dispatchEvent(event);
    });
}

ChatHubExchange.prototype.Logout = function () {
    this.Socket.Close();
}

ChatHubExchange.prototype.LoginAsync = function (token) {
    var _this = this;
    var connect = Timeout( new Promise((resolve, reject) => {
        function onConnect() {
            _this.Socket.removeEventListener("open", onConnect);
            resolve();
        }
        _this.Socket.addEventListener("open", onConnect);
        _this.Socket.Connect();
    }), _this.Timeout);
    return connect.then((data) => {
        return SendAsync.call(_this, 'Login', token);
    });
}

ChatHubExchange.prototype.Login = function (token, success, error) {
    this.LoginAsync(token).then(success).catch(error);
}

ChatHubExchange.prototype.SendAsync = function (to, msgType, contentType, content) {
    var _this = this;
    var check = new Promise((resolve, reject) => {
        if (to && msgType && contentType) {
            resolve({
                To: to,
                MsgType: msgType,
                Content: content,
                ContentType: contentType
            });
        }
        else {
            reject('ArgsIsNull');
        }
    });
    return check.then((data) => {
        return SendAsync.call(_this, 'Send', data);
    });
}

ChatHubExchange.prototype.Send = function (to, msgType, contentType, content, success, error) {
    this.SendAsync(to, msgType, contentType, content).then(success).catch(error);
}

ChatHubExchange.prototype.LoadAsync = function (sessionId, pageIndex) {
    var _this = this;
    var check = new Promise((resolve, reject) => {
        if (sessionId && sessionId > 0 && pageIndex && pageIndex > 0) {
            resolve({
                SessionId: sessionId,
                PageIndex: pageIndex
            });
        }
        else {
            reject('ArgsIsNull');
        }
    });
    return check.then((data) => {
        return SendAsync.call(_this, 'Load', data);
    });
}

ChatHubExchange.prototype.Load = function (sessionId, pageIndex, success, error) {
    this.LoadAsync(sessionId, pageIndex).then(success).catch(error);
}

function OnMessage(data, eventTarget) {
    var pack = new ChatHubPackage(data);
    if (pack.Key) {
        var event = generateEvent(pack.Key);
        event.Key = pack.Key;
        event.Timestamp = pack.Timestamp;
        event.Data = pack.Data;
        eventTarget.dispatchEvent(event);
    }
}

function SendAsync(key, data) {
    var _this = this;
    var request = Timeout(new Promise((resolve, reject) => {
        var pack = new ChatHubPackage({ Key: key, Data: data });
        var message = JSON.stringify(pack);
        var callback = function (event) {
            if (event.Key === pack.Key && event.Timestamp === pack.Timestamp) {
                _this.removeEventListener(pack.Key, callback);
                if (event.Data === 'OK') {
                    resolve(event.Data);
                }
                else {
                    reject(event.Data);
                }
            }
        };
        _this.addEventListener(pack.Key, callback);
        _this.Socket.Send(message);
    }), _this.Timeout);
    return request;
}

function Timeout(premise, time) {
    var timeout = new Promise((resolve, reject) => {
        setTimeout(() => {
            reject('timeout');
        }, time);
    });
    return Promise.race([premise, timeout]);
}

let ChatHubPackage = function (obj) {
    this.Key = undefined;
    this.Timestamp = undefined;
    this.Data = undefined;

    if (obj) {
        var data = undefined;
        switch (typeof obj) {
            case 'string':
                data = JSON.parse(obj);
                break;
            case 'object':
                data = obj;
                break;
        }

        if (data && data.Key && data.Data) {
            this.Key = data.Key;
            this.Data = data.Data;
            if (data.Timestamp) {
                this.Timestamp = data.Timestamp;
            }
            else {
                this.Timestamp = Date.now()
            }
        }
    }
}

export { ChatHubExchange, ChatHubPackage }