/** 自定义websocket服务 */
import workerTimer from "./worker"
export default class SocketService {
  /**
   * 单例
   */
  static instance = null;
  static get Instance () {
    if (!this.instance) {
      this.instance = new SocketService()
    }
    return this.instance
  }
  /** 和服务端连接的socket对象 */
  ws = null;
  /** 服务器连接地址 */
  wsUrl = null;
  token = null;
  /** 标识是否连接成功 */
  connected = false;

  /** 重新连接间隔(ms) */
  connectRetryTime = 3000;
  /** 重新连接次数 */
  connectRetryCount = 0;
  timeout = 20 * 1000; //20秒一次心跳
  heartbeatInterval = null; //心跳倒计时
  lastRunTime = Date.now();

  /** 定义连接服务器的方法 */
  connect (wsUrl, token) {
    // 连接服务器
    if (!window.WebSocket) {
      return console.log("您的浏览器不支持WebSocket")
    }
    this.token = token
    this.wsUrl = wsUrl
    this.ws = new WebSocket(this.wsUrl)
    // 连接成功的事件
    this.ws.onopen = () => {
      console.log("Socket连接服务端成功,您的连接地址：" + this.wsUrl)
      this.connected = true
      // 重置重新连接的次数
      this.connectRetryCount = 0
      this.start()
      this.sendWebsocket(JSON.stringify({ token: token }))
    }
    // 发送消息到WebSocket服务器
    this.sendWebsocket = (e) => {
      console.log("发送消息", e)
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(e)
      }
    }
    //服务器返回的数据
    this.ws.onmessage = (e) => {
      console.log("返回的数据", e?.data)
      window.dispatchEvent(
        new CustomEvent("onmessageWS", {
          detail: {
            data: JSON.parse(e?.data),
          },
        })
      )
    }
    // 1.连接服务端失败
    // 2.当连接成功之后, 服务器关闭的情况
    this.ws.onclose = () => {
      console.log("服务器关闭")
      this.connected = false
      this.stop()
    }
    //连接服务端失败
    this.ws.onerror = (e) => {
      console.log("连接服务端失败")
      this.connected = false
      this.stop()
      this.reconnect()
    }
    // 发送心跳
    this.start = () => {
      // let currentTime = Date.now();
      // let delay = this.timeout - (currentTime - this.lastRunTime); // 计算延迟
      // if (delay < 0) delay = 0;
      // this.heartbeatInterval = setTimeout(() => {
      //   let ping = { msgtype: "0" };
      //   this.sendWebsocket(JSON.stringify(ping)); // 发送心跳消息

      //   this.lastRunTime = Date.now();
      //   this.start(); // 递归调用
      // }, delay);
      // this.heartbeatInterval = setInterval(async () => {
      //   console.log("发送心跳");
      //   let ping = { msgtype: "0" };
      //   this.sendWebsocket(JSON.stringify(ping)); // 发送心跳消息
      // }, this.timeout); // 每 秒发送一次心跳

      this.heartbeatInterval = workerTimer.setInterval(() => {
        let ping = { msgtype: "0" }
        this.sendWebsocket(JSON.stringify(ping)) // 发送心跳消息
      }, this.timeout)
    }

    // 关闭心跳
    this.stop = () => {
      console.log("关闭心跳")
      if (this.heartbeatInterval) {
        workerTimer.clearInterval(this.heartbeatInterval) // 停止心跳检测定时器
        // clearInterval(this.heartbeatInterval); // 停止心跳检测定时器
      }
    }
    //关闭连接
    this.close = (e) => {
      if (this.ws) {
        console.log("关闭连接")
        this.ws.close()
      }
    }
    //重新连接
    this.reconnect = (e) => {
      this.connectRetryCount++
      if (this.connectRetryCount >= 10) {
        console.log("Socket出错,已断开连接")
      } else {
        console.log("Socket出错:第" + this.connectRetryCount + "次重新连接尝试！")
        setTimeout(async () => {
          this.connect(this.wsUrl, this.token)
        }, this.connectRetryTime) // 在一定时间后重连 WebSocket
      }
    }
  }
}
