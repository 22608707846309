<template>
    <div>
        <slot name="DetialPreview">
            <h3>预览</h3>
        </slot>
        <div class="detail-list" v-for="item in Content">
            <el-divider></el-divider>
            <h3 v-text="item.title"></h3>
            <div v-if="item.type==='RichText'" v-html="item.info"></div>
            <div v-else-if="item.type==='Option'">
                <ul>
                    <li v-for="option in JSON.parse(item.info)" v-text="option"></li>
                </ul>
            </div>
            <div v-else-if="item.type==='OptionAndText'">
                <el-card class="box-card" v-for="option in JSON.parse(item.info)">
                    <div slot="header" class="clearfix">
                        <span>{{option.Option}}</span>
                    </div>
                    <div class="text item" v-html="option.Content"></div>
                </el-card>
            </div>
            <div v-else-if="item.type==='Image'">
                <el-carousel height="150px">
                    <el-carousel-item v-for="imgAddr in JSON.parse(item.info)">
                        <el-image :src="FileBaseAddr + imgAddr" fit="contain"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div v-else-if="item.type==='File'">
                <ul>
                    <li v-for="file in JSON.parse(item.info)">
                        <el-link type="primary" :href="FileBaseAddr + file.url" v-text="file.name"></el-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            FileUploadUrl: String,
            FileBaseAddr: String,
            Content: Array
        }
    }
</script>
<style>
    .detail-list div p{
        text-indent: 28px;
    }
</style>
