import { createEvent, generateEvent } from './CreateEvent.js';

let ChatHubSocket = function (addr) {
    var _this = this;

    var eventTarget = createEvent(_this);
    eventTarget.addEventListener('open', function (event) { _this.onOpen(event); });
    eventTarget.addEventListener('close', function (event) { _this.onClose(event); });
    eventTarget.addEventListener('message', function (event) { _this.onMessage(event); });
    eventTarget.addEventListener('error', function (event) { _this.onError(event); });

    var socket = undefined;
    _this.Addr = addr;
    _this.readyState = WebSocket.CLOSED;
    if (window && window.WebSocket) {
        _this.Type = "WebSocket";
    }
    else if (wx && wx.connectSocket) {
        _this = "WechatSocket";
    }

    _this.Connect = function () {
        switch (_this.Type) {
            case "WebSocket":
                _this.readyState = WebSocket.CONNECTING;
                socket = new WebSocket(_this.Addr);
                socket.onopen = onOpen;
                socket.onclose = onClose;
                socket.onerror = onError;
                socket.onmessage = function (evt) {
                    onMessage(evt.data);
                }
                break;
            case "WechatSocket":
                _this.readyState = WebSocket.CONNECTING;
                socket = wx.connectSocket(_this.Addr);
                socket.onOpen = onOpen;
                socket.onClose = onClose;
                socket.onError = onError;
                socket.onMessage = onMessage;
                break;
        }
    }

    _this.Send = function (data) {
        switch (_this.Type) {
            case "WebSocket":
                socket.send(data);
                break;
            case "WechatSocket":
                socket.send({ data: data });
                break;
        }
    }

    _this.Close = function () {
        _this.readyState = WebSocket.CLOSING;
        socket.close();
    }

    function onOpen(evt) {
        _this.readyState = WebSocket.OPEN;
        var e = generateEvent('open');
        eventTarget.dispatchEvent(e);
    }
    function onClose(evt) {
        _this.readyState = WebSocket.CLOSED;
        var e = generateEvent('close');
        e.code = evt.code;
        e.reason = evt.reason;
        eventTarget.dispatchEvent(e);
    }
    function onError(evt) {
        var e = generateEvent('error');
        e.message = evt;
        eventTarget.dispatchEvent(e);
    }
    function onMessage(data) {
        var e = generateEvent('message');
        e.data = data;
        eventTarget.dispatchEvent(e);
    }
}

ChatHubSocket.prototype.onOpen = function (evt) { }
ChatHubSocket.prototype.onClose = function (evt) { }
ChatHubSocket.prototype.onMessage = function (evt) { }
ChatHubSocket.prototype.onError = function (evt) { }

export { ChatHubSocket }