let Commodity = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取商家商品列表
     * @param keyWord
     * @param categoryId
     * @param state
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.MerchantCommodity = function (keyWord, categoryId, state, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/Manager/Merchant';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (state) {
            url += `/${state}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取自营商品列表
     * @param keyWord
     * @param categoryId
     * @param state
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.OwnCommodity = function (keyWord, categoryId, state, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/Manager/Owned';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (state) {
            url += `/${state}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.CommodityList = function (keyWord, categoryId, pageIndex,dataTotal,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/Organization';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (dataTotal) { 
            url += `/${dataTotal}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 处方药列表
     * @param {*} keyWord 
     * @param {*} categoryId 
     * @param {*} pageIndex 
     * @param {*} dataTotal 
     * @param {*} success 
     * @param {*} error 
     */
    this.PrescriptionList = function (keyWord, categoryId, pageIndex,dataTotal,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/Organization/Prescription';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (dataTotal) { 
            url += `/${dataTotal}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.IntegralCommodityList = function (keyWord, categoryId, pageIndex, dataTotal, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/OrganizationIntegral';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (dataTotal) { 
            url += `/${dataTotal}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 机构待审核商品
     * @param {*} keyWord 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
    this.OrgAudit = function (keyWord, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/OrgAudit';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 机构驳回商品
     * @param {*} keyWord 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
    this.OrgSeedBack = function (keyWord, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/OrgSeedBack';

        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 新增商品
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.AddCommodity = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 编辑商品
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.EditCommodity = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取限时抢购商品列表
     * @param keyWord
     * @param categoryId
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.SaleCommodity = function (keyWord, categoryId, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/Manager/FlashSalePages';
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    /**
     * 新增限时抢购商品
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.AddSaleCommodity = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/FlashSale';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 编辑限时抢购商品
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.EditSaleCommodity = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/FlashSale';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }



    /**
     * 上下架商品
     * @param id
     * @param success
     * @param error
     * @constructor
     */
    this.UporDown = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/UpOrDown/' + id;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 热销商品
     * @param id
     * @param success
     * @param error
     * @constructor
     */
    this.IsHot = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/SetHot/' + id;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 新品商品
     * @param id
     * @param success
     * @param error
     * @constructor
     */
     this.IsNew = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/SetNewProducts/' + id;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 每日推荐
     * @param id
     * @param success
     * @param error
     * @constructor
     */
    this.IsCommended = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/SetCommended/' + id;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 商品详情
     * @param id
     * @param success
     * @param error
     * @constructor
     */
    this.Detail = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/ManagerDetail/' + id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 删除商品
     * @param id
     * @param success
     * @param error
     * @constructor
     */
    this.Remove = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/' + id;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取处方药咨询审查列表
     * @param startTime
     * @param endTime
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
     this.PrescriptionRecordManager = function (startTime, endTime, keyWord, pageIndex,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/PrescriptionRecord/Manager';
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 处方信息详情
     * @param id
     * @param success
     * @param error
     * @constructor
     */
     this.PrescriptionRecord = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/PrescriptionRecord/Manager/' + id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 添加问诊
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.InterviewRecord = function (item, success, error) {
        var url = ServiceBaseAddress + `/api/PrescriptionRecord/Manager/InterviewRecord`;
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 问诊保存sessionid
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.Inquiries = function (recordId ,sessionId , success, error) {
        var url = ServiceBaseAddress + `/api/PrescriptionRecord/Manager/Inquiries/${recordId}/${sessionId }`;
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 查询问诊
     * @param recordId 
     * @param success
     * @param error
     * @constructor
     */
     this.GetInterviewRecord = function (recordId , success, error) {
        var url = ServiceBaseAddress + '/api/PrescriptionRecord/Manager/InterviewRecord/' + recordId ;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 处方药审核
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.Audit = function (item, success, error) {
        var url = ServiceBaseAddress + `/api/PrescriptionRecord/Audit/${item}`;
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设置商品规格
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.SetSpecifications = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/SetSpecifications';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取机构运费模板
     * @param organizationId
     * @param success
     * @param error
     * @constructor
     */
    this.FreightTemplate = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate/List/' + organizationId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.FreightDetail = function (templateId, success, error) {
        var url = ServiceBaseAddress + '/api/FreightTemplate/' + templateId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.GetShopSetting = function (success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/IsShareDistribution';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 处方药药品参数
     * @param {*} success 
     * @param {*} error 
     */
    this.GetPrescription = function (success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Parameter/Prescription';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Commodity }
