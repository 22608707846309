
let Msg = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    this.GetSessionByUserId = function (userId, userId2,pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Session/User/' + userId;
        if (userId2) {
            url += `/${userId2}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.GetMessagesBySequence = function (sessionId,startSequence, endSequence ,success, error) {
        var url = ServiceBaseAddress + `/api/Session/${sessionId}/Messages/${startSequence}/${endSequence}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 根据用户ID获取信息
     * @param {*} SessionId 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
    this.GetMessagesBySessionId = function (SessionId, pageIndex, success, error) {
        var url = ServiceBaseAddress + `/api/Session/${SessionId}/Messages?pageIndex=${pageIndex}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取所有session
     * @param {*} userId 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
     this.GetSession = function (pageIndex, success, error) {
         var url = ServiceBaseAddress + "/api/Session";
         if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }/**
     * 根据类型获取消息通知列表
     * @param {*} success 
     * @param {*} error 
     */
    this.GetMessageList = function (platform, type, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + `/api/Message/GetMessageList/${platform}/${type}`
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        if (keyWord) {
            url += `/${keyWord}`
        } else {
            url += '/%20'
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }

}
export { Msg }

