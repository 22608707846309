<template>
    <div class="btnDiv">
        <el-button type="primary" size="small" @click="handleDownloadPdfClick" plain>导出PDF</el-button>
    </div>
</template>

<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
    name: "headCenter",
    props: {
        exportDataList: Array,
    },
    data() {
        return {
            exportData: []
        }
    },
    methods: {
        /**
         * 下载为PDF
         */
        handleDownloadPdfClick() {
            this.exportDataList.forEach((item) => {
                this.exportPdf(item.divId, item.outFileName);
            })

        },
        exportPdf(dom, outFileName) {
            var element = document.querySelector(dom); // 这个dom元素是要导出pdf的div容器
            setTimeout(() => {
                html2Canvas(element).then(function (canvas) {
                    var contentWidth = canvas.width;
                    var contentHeight = canvas.height;

                    // 一页pdf显示html页面生成的canvas高度;
                    var pageHeight = contentWidth / 592.28 * 841.89;
                    // 未生成pdf的html页面高度
                    var leftHeight = contentHeight;
                    console.log(pageHeight);
                    console.log(leftHeight);
                    // 页面偏移
                    var position = 0;
                    // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                    var imgWidth = 595.28;
                    var imgHeight = 592.28 / contentWidth * contentHeight;

                    var pageData = canvas.toDataURL('image/jpeg', 1.0);

                    var pdf = new JsPDF('', 'pt', 'a4');

                    // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                    // 当内容未超过pdf一页显示的范围，无需分页
                    if (leftHeight < pageHeight) {
                        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
                    } else {
                        while (leftHeight > 0) {
                        console.log(leftHeight);
                            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight;
                            position -= 841.89;
                            // 避免添加空白页
                            if (leftHeight > 0) {
                                pdf.addPage();
                            }
                        }
                    }
                    pdf.save(outFileName + '.pdf');
                });
                // element.style.height = param.minHeight
            }, 0);
        },
    }
}
</script>

<style scoped>
    .btnDiv{
        display: inline-block;
        margin-left: 10px;
    }
</style>