<template>
  <div id="app" v-title data-title="管理后台">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import { Message } from 'element-ui'
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  created() {
    if (window.location.href.indexOf("/login") == -1) {
      this.initWebSocket();
    }
    //  document.addEventListener('visibilitychange', function () {
    //   var isHidden = document.hidden
    //   console.log(isHidden)
    //   if (isHidden) {
    //     //切离该页面时执行
    //     Message({
    //       type: 'success',
    //       message: '隐藏',
    //     })
    //   } else {
    //     //切换到该页面时执行
    //     Message({
    //       type: 'success',
    //       message: '显示',
    //     })
    //   }
    // })
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function() {
         this.isRouterAlive = true
      })
    },
    async initWebSocket() {
      let token = JSON.parse(sessionStorage.getItem(this.ClientId + "Token")).access_token;
      this.$socket.connect(this.Services.MsgWss, token);
    },
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

::v-deep .optionBtn:last-child::after {
  content: "" !important;
  margin: 0 !important;
}


</style>
